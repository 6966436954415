body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

label {
  display: block;
  margin-bottom: 10px;
}

input {
  margin-left: 10px;
}

.headerLogin {
  background: rgb(108, 29, 69);
  float: left;
  width: 100%;
  height: 160px;
  min-height: 20%;
  text-align: center;
  margin-bottom: 50px;
}

.logo {
  width: 200px;
  margin: 20px 0;
}

.full {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #333;
  top: 0;
  left: 0;
  text-align: center;
}

.full a {

}
